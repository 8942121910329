<template>
  <v-row>
    <VDatePicker
        v-model="currentDate"
        class="col-md-3"
        first-day-of-week="1"
        width="100%"/>

    <v-col class="col-md-9">

      <p v-if="this.product" class="text-h4">
        {{ this.product }}
        <span v-if="this.country"> > {{ this.country }}</span>
      </p>

      <v-tabs
          v-model="tab"
          background-color="transparent"
          color="basil"
          grow
      >
        <v-tab
            v-for="item in tabs"
            :key="item.type"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="item in tabs"
            :key="item.type"
        >
          <ChartView
              v-model="currentDate"
              :method="fetchData"
              :type="item.type"
          />
        </v-tab-item>
      </v-tabs-items>

    </v-col>

  </v-row>
</template>

<script>
import { getStatistics } from '@/api/endpoints/statistics.js';
import ChartView from '@/components/ChartView.vue';
import tab_items from '@/data/tab_items.js';
import dayjs from 'dayjs';

export default {
  name: 'SaleProduct',

  components: {
    ChartView,
  },

  data: function () {
    return {
      tabs: tab_items,
      tab: null,
      currentDate: new dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    };
  },

  computed: {
    product: function () {
      if (!this.$route.params.product
          || this.$route.params.product.length <= 0) {
        return false;
      }

      return this.$route.params.product;
    },
    country: function () {
      if (!this.$route.params.country
          || this.$route.params.country.length <= 0) {
        return false;
      }

      return this.$route.params.country;
    },
  },

  methods: {
    fetchData(type, date) {
      return getStatistics(type, date,
          this.$route.params.productId,
          this.$route.params.countryId);
    },
  },
};
</script>
